import styled from "styled-components";

// assets
import ArrowLeft from "assets/svg/arrows/ArrowLeft";
import ArrowRight from "assets/svg/arrows/ArrowRight";

// components
import ScrollToTopLink from "components/elements/ScrollToTopLink";

function determineHoverBgColor(color) {
  if (color === "transparent") {
    return "transparent";
  } else if (color === "black") {
    return "#111";
  } else {
    return "#cacaca";
  }
}

function determineContentColor(color) {
  return color === "black" ? "white" : "black";
}

export default function Button({
  href,
  to,
  onClick,
  color = "white",
  arrow,
  disabled,
  style,
  text,
  size,
}) {
  const handleClick = (e) => {
    if (disabled || href || to) return;

    if (onClick) {
      onClick(e);
    }
  };

  const arrowLeft = arrow === "left";
  const arrowRight = arrow === "right";

  const StyledButtonContainer = (
    <StyledButton
      bgColor={color}
      className={disabled ? "disabled" : ""}
      onClick={handleClick}
      style={style}
      text={text}
      arrowLeft={arrowLeft}
      arrowRight={arrowRight}
      size={size}
    >
      {arrowLeft && (
        <ArrowLeft stroke={determineContentColor(color)} paddingTop="4px" />
      )}
      <ButtonText fontColor={determineContentColor(color)} size={size}>
        {text}
      </ButtonText>
      {arrowRight && (
        <ArrowRight stroke={determineContentColor(color)} paddingTop="4px" />
      )}
    </StyledButton>
  );

  return (
    <div>
      {href ? (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {StyledButtonContainer}
        </a>
      ) : to ? (
        <ScrollToTopLink to={to}>{StyledButtonContainer}</ScrollToTopLink>
      ) : (
        StyledButtonContainer
      )}
    </div>
  );
}

const StyledButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex-shrink: 0;
  gap: 8px;

  /* Default size */
  height: 56px;
  padding: 20px 32px;
  ${(props) => props.arrowLeft && "padding-left: 24px;"}
  ${(props) => props.arrowRight && "padding-right: 24px;"} 

  /* Small size */
  ${(props) =>
    props.size === "small" &&
    `
    height: 36px;
    padding: 10px 20px;
    ${(props) => props.arrowLeft && "padding-left: 14px;"} 
    ${(props) => props.arrowRight && "padding-right: 14px;"} 
  `}

  margin-top: ${(props) => props.marginTop || "0px"};

  border: 0;
  border-radius: 56px;

  background: ${(props) => props.bgColor || "var(--White, #FFF)"};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};

  svg {
    margin: 0;
    vertical-align: middle;

    ${(props) => props.arrowLeft && "margin-right: 5px;"}
    ${(props) => props.arrowRight && "margin-left: 5px;"}

    /* Small button adjustments */
    ${(props) => props.size === "small" && "width: 20px; "}
    ${(props) => props.size === "small" && "padding-top: 2px !important;"}
  }

  &:hover {
    background: ${(props) => determineHoverBgColor(props.bgColor)};
  }

  &.disabled {
    cursor: default;
    opacity: 0.6;
    &:hover {
      background: ${(props) => props.bgColor || "var(--White, #FFF)"};
    }
  }
`;

const ButtonText = styled.div`
  font-family: Inter;
  font-size: clamp(1.3rem, 1.6vw, 1.45rem);
  ${(props) =>
    props.size === "small" && "font-size: clamp(0.8rem, 1.1vw, 1.05rem);"}
  font-style: normal;
  font-weight: 400;

  color: ${(props) => props.fontColor || "var(--Black, #000)"};
  line-height: 140%;
  text-align: ${(props) => props.textAlign || "left"};
  margin-bottom: 2px;

  white-space: nowrap;
`;
