import React from "react";
import styled from "styled-components";

// components
import { Heading2, Body1, Heading5 } from "components/TextStyles";
// import image from "assets/img/unused/dandelionField.png";

// assets
// import Dandelion from "assets/img/gmlogos/greyLogo.png";
import GMLogo from "assets/svg/gmlogos/GMLogoSmallWhite";

export default function Top() {
  return (
    <>
      <Background />
      <Wrapper>
        <Paper>
          <div>
            <GMLogo width="48px" />
            <Heading2
              fontColor="#4bffdf"
              fontFamily="Valizas"
              lineHeight="130%"
              fontWeight="550"
            >
              Our Manifesto
            </Heading2>
            <Body1>An ESG Imperative</Body1>
          </div>
          <Body1>
            In the dynamic landscape of today’s workforce, we celebrate age
            diversity as a foundation for resilience, adaptability, and profound
            experience. Introducing the Grey Matters™ Manifesto; in which we ask
            you to endorse a workplace paradigm that values the wisdom and
            vitality of all generations. It’s time to break free from the
            irrational hierarchy and bridge gaps in intergenerational
            perspectives, strength, and resilience.
          </Body1>
          <Section>
            <Heading5 fontColor="#4bffdf">
              Our Purpose: A Flourishing, Age-Inclusive Future
            </Heading5>
            <Body1>
              Grey Matters™ heralds a transformative era, where age inclusivity
              isn't just a social obligation but a strategic masterstroke. Join
              forward-thinking companies and proudly display the Grey Matters™
              dandelion icon—a symbol of hope, strength, resilience, renewal,
              and transformation—on your website, HR documentation and social
              media platforms.
            </Body1>
          </Section>
          <Section>
            <Heading5 fontColor="#4bffdf">
              Our Ask: Pioneering Age-Inclusive Practices for ESG Impact
            </Heading5>
            <Body1>
              Demonstrate your commitment by showcasing inclusive practices:
            </Body1>
            <Body1>
              • Fortify your workforce against age discrimination through
              Diversity, Equity, Inclusion and Belonging briefings and training.
            </Body1>
            <Body1>
              • In the same spirit of graduate training schemes and life-long
              learning, provide your over 45s with optionality to transition to
              part-time/gig/just-in-time roles in a controlled, safe and
              stressless way.
            </Body1>
            <Body1>
              • Fortify your culture by socialising extended careers pathways
              that provide flexibility to the organisation and individual.
            </Body1>
            <Body1>
              • Craft job advertisements that focus on capability and potential
              and transcend age biases.
            </Body1>
          </Section>
          <Section>
            <Heading5 fontColor="#4bffdf">
              Fair & Transparent Opportunities and Communication
            </Heading5>
            <Body1>
              Every job opportunity should be a door open to all. Share your age
              diversity statistics transparently, fostering fairness within and
              beyond your company.
            </Body1>
          </Section>
          <Section>
            <Heading5 fontColor="#4bffdf">
              Retirement Policies: A New Horizon
            </Heading5>
            <Body1>
              <strong>Bid farewell to forced retirement</strong> by partnering
              with age transitioning platforms, like Grey Matters™, to assist
              your employees pivot their careers in a structured way.
            </Body1>
            <Body1>
              Amplify wisdom: Reskilling & mentoring/coaching opportunities for
              the over 45s
            </Body1>
            <Body1>
              • Lifelong learning isn't a choice; it's a necessity. Continue to
              invest in future skills credentialing and learning for your over
              45s. You can teach a midlife dog new tricks.
            </Body1>
            <Body1>
              • Tap into the wealth of experience and knowledge by pairing your
              over 45s with younger talent in coaching/mentoring and supporting
              roles.
            </Body1>
          </Section>
          <Section>
            <Heading5 fontColor="#4bffdf">
              Recognition of Success: Celebrate Diversity Champions
            </Heading5>
            <Body1>
              Establish awards, share success stories, and celebrate those
              committed to age diversity. Illuminate the impact and benefits of
              age inclusivity, internally and externally. Role modelling and
              elevating wins is one of the most powerful ways to educate and
              break down myths on modern ageing.
            </Body1>
          </Section>
          <Section>
            <Heading5 fontColor="#4bffdf">
              Age Inclusive Commitments: Pledges for ESG Impact
            </Heading5>
            <Body1>Demonstrate your commitment through:</Body1>
            <Body1>
              • <strong>(Un)Retirement Transition Support:</strong> Offer the
              over 45s access to a controlled, safe and stressless way of
              transiting by building intergenerational understanding, knowledge
              and resilience in your workforce.
            </Body1>
            <Body1>
              • <strong>Inclusive Apprenticeships:</strong> Offer
              apprenticeships that are open to all.
            </Body1>
            <Body1>
              • <strong>Returner Programmes:</strong> Alumni/Retiree returner
              programmes that offer meaningful opportunities.
            </Body1>
            <Body1>
              • <strong>Proactive Gig Employment:</strong> Gig employment that
              proactively recruits and values the over 45s on a
              short-term/just-in-time/part-time or volunteering basis.
            </Body1>
            <Body1>
              • <strong>Age Inclusive Metrics:</strong> Report on your age
              inclusive metrics on Management and Board reporting dashboards.
            </Body1>
          </Section>
          <Section>
            <Body1>
              Grey Matters™ stands as a rallying cry for a workforce where age
              is not a limitation but a beacon of strength. Recognising that
              ageing is a significant social issue, we assert our collective
              responsibility to solve it. Together, let's champion an inclusive
              future that harnesses the full spectrum of human experience and
              potential, contributing to a sustainable and equitable world
              promoting economic wealth.
            </Body1>
          </Section>
        </Paper>
        {/* <RightSide>
          <GreyDandelion />
        </RightSide> */}
      </Wrapper>
    </>
  );
}

// const GreyDandelion = styled.img.attrs(() => ({ src: Dandelion }))`
//   width: clamp(360px, 60vw, 1000px);
//   height: clamp(360px, 60vw, 1000px);
//   filter: brightness(120%);
//   opacity: 0.4;
//   z-index: -1;
// `;

const Background = styled.section`
  background-color: #12141a;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
`;

const Wrapper = styled.section`
  background-color: #12141a;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 120px 80px 60px 80px;

  @media (max-width: 860px) {
    padding: 120px 0;
  }
`;

const Paper = styled.section`
  // box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  background-color: #12141a;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 48px;
  width: 50%;
  margin: 20px;
  padding: 40px;

  @media (max-width: 1440px) {
    width: 80%;
  }

  @media (max-width: 1080px) {
    width: 100%;
  }

  @media (max-width: 1080px) {
    padding: 0 20px;
  }

  z-index: 1;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
