import React from "react";
import styled from "styled-components";
import "style/index.css";

// components
import { Body1, Body2, Heading2 } from "components/TextStyles";

// assets
import portraitBackground from "assets/img/teamheadshots/portraitBackground.png";
import Julija from "assets/img/teamheadshots/julija.png";
import Claire from "assets/img/teamheadshots/claire.png";
import Alex from "assets/img/teamheadshots/alex.png";
import Bridget from "assets/img/teamheadshots/bridget.png";
// import Avivah from "assets/img/teamheadshots/avivah.png";
// import John from "assets/img/teamheadshots/john.png";
import Sri from "assets/img/teamheadshots/sri.png";
import Jayson from "assets/img/teamheadshots/jayson.png";
// import Ross from "assets/img/teamheadshots/ross.png";
// import Jp from "assets/img/teamheadshots/jp.png";
// import Yasmin from "assets/img/teamheadshots/yasmin.png";
import LinkedInIcon from "assets/svg/icons/socialmediaicons/LinkedInIcon";
// import GitHubIcon from "assets/svg/GitHubIcon";

export default function LeaderShipTeam() {
  return (
    <>
      <Wrapper className="container flexSpaceCenter">
        <Heading2 textAlignSub="center">Grey Matters Leadership Team</Heading2>
        <BoardMembers>
          <BoardMemberTile>
            <Portrait person={Claire}></Portrait>
            <Body1>Claire Canham</Body1>
            <Body2 maxFontSize="1rem">CEO & Co-founder</Body2>
            <Socials>
              <a
                href="https://www.linkedin.com/in/clairerawlins/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </a>
            </Socials>
          </BoardMemberTile>
          <BoardMemberTile>
            <Portrait person={Julija}></Portrait>
            <Body1>Julija McDowell</Body1>
            <Body2 maxFontSize="1rem">Co-founder & MD Learning</Body2>
            <Socials>
              <a
                href="https://www.linkedin.com/in/julijalearie/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </a>
            </Socials>
          </BoardMemberTile>
          <BoardMemberTile>
            <Portrait person={Alex}></Portrait>
            <Body1>Alex Taylor</Body1>
            <Body2 maxFontSize="1rem">CTO & AI Wizard</Body2>
            <Socials>
              <a
                href="https://www.linkedin.com/in/alextayl0r/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </a>
            </Socials>
          </BoardMemberTile>
          <BoardMemberTile>
            <Portrait person={Bridget}></Portrait>
            <Body1>Bridget Gray</Body1>
            <Body2 maxFontSize="1rem">MD Greytasker</Body2>
            <Socials>
              <a
                href="https://www.linkedin.com/in/bridgetgraygtc/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </a>
            </Socials>
          </BoardMemberTile>
          <BoardMemberTile>
            <Portrait person={Sri}></Portrait>
            <Body1>Sri Srinivasan</Body1>
            <Body2 maxFontSize="1rem">Chief Commercial Officer</Body2>
            <Socials>
              <a
                href="https://www.linkedin.com/in/ssrinivasan1/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </a>
            </Socials>
          </BoardMemberTile>
          <BoardMemberTile>
            <Portrait person={Jayson}></Portrait>
            <Body1>Jayson Wright</Body1>
            <Body2 maxFontSize="1rem">Creative Director</Body2>
            <Socials>
              <a
                href="https://www.linkedin.com/in/jayson-wright-a846b933/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </a>
            </Socials>
          </BoardMemberTile>
          <BoardMemberTile>
            <Portrait></Portrait>
            <Body1>Announcing Soon</Body1>
            <Body2 maxFontSize="1rem">MD Health</Body2>
            <Socials>
              <a
                href="https://blackratart.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </a>
            </Socials>
          </BoardMemberTile>
        </BoardMembers>

        <Heading2 textAlignSub="center">Grey Matters Advisory Board</Heading2>
        <BoardMembers>
          <BoardMemberTile>
            <Portrait></Portrait>
            <Body1>Announcing soon</Body1>
            <Body2>Announcing soon</Body2>
            <Socials>
              {/* <a href="https://dev.blackratart.com"> */}
              <LinkedInIcon />
              {/* </a> */}
            </Socials>
          </BoardMemberTile>
          <BoardMemberTile>
            <Portrait></Portrait>
            <Body1>Announcing soon</Body1>
            <Body2>Announcing soon</Body2>
            <Socials>
              {/* <a href="https://dev.blackratart.com"> */}
              <LinkedInIcon />
              {/* </a> */}
            </Socials>
          </BoardMemberTile>
          <BoardMemberTile>
            <Portrait></Portrait>
            <Body1>Announcing soon</Body1>
            <Body2>Announcing soon</Body2>
            <Socials>
              {/* <a href="https://dev.blackratart.com"> */}
              <LinkedInIcon />
              {/* </a> */}
            </Socials>
          </BoardMemberTile>
          <BoardMemberTile>
            <Portrait></Portrait>
            <Body1>Announcing soon</Body1>
            <Body2>Announcing soon</Body2>
            <Socials>
              {/* <a href="https://dev.blackratart.com"> */}
              <LinkedInIcon />
              {/* </a> */}
            </Socials>
          </BoardMemberTile>
        </BoardMembers>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: calc(10vh + 30px);
  }

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 560px) {
    align-items: center;
  }

  gap: 32px;

  margin-top: 10vh;
`;

const BoardMembers = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  flex-wrap: wrap;

  width: 100%;
  row-gap: 3vh;
  column-gap: 5vw;

  margin-bottom: 8vh;
`;

const BoardMemberTile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  gap: 5px;
`;

const Portrait = styled.section`
  position: relative;
  width: 13rem;
  height: 13rem;

  overflow: hidden;
  background-image: url(${(props) => props.person}), url(${portraitBackground});
  filter: grayscale(100%);

  background-color: lightgray;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  border-radius: 0.75rem;

  @media (max-width: 560px) {
    width: 10rem;
    height: 10rem;
  }
`;

const Socials = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;

  column-gap: 5px;
  margin-top: 5px;
`;
