import React, { useState, useEffect } from "react";
import styled from "styled-components";

// Components
import { Heading1, Body1 } from "components/TextStyles";
import Button from "components/styledelements/Button";
// Assets
import HeaderImage from "assets/img/aigeneratedimages/landingHeaderWoman.png";
import ArrowDown from "assets/svg/arrows/ArrowDown";

export default function Header() {
  const startingHeight = -window.innerHeight * 0.85;
  const breakpoint = 1080;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [scrollY, setScrollY] = useState(startingHeight);
  const [imageRevealed, setImageRevealed] = useState(false);

  useEffect(() => {
    if (window.scrollY > 0) {
      setImageRevealed(true);
    }

    const updateWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    const handleScroll = (event) => {
      if (windowWidth <= breakpoint) return;

      if (!imageRevealed) {
        if (scrollY <= startingHeight && event.deltaY < 0) {
          event.preventDefault();
          return;
        }

        event.preventDefault();
        setScrollY((prev) => prev + event.deltaY);
      }
    };

    window.addEventListener("resize", updateWidth);
    window.addEventListener("scroll", handleScroll, { passive: false });
    window.addEventListener("wheel", handleScroll, { passive: false });

    return () => {
      window.removeEventListener("resize", updateWidth);
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("wheel", handleScroll);
    };
  }, [imageRevealed, windowWidth, scrollY, startingHeight]);

  function calculateImageProperties() {
    if (windowWidth <= breakpoint) {
      return { translateY: 0, grayscale: 0, opacity: 0.7 }; // Fixed values for small screens
    }

    const maxTranslate = 0;
    const maxOpacityTranslateY = 0;
    const minOpacityTranslateY = startingHeight;

    if (imageRevealed) {
      return { translateY: maxTranslate, grayscale: 0, opacity: 0.7 };
    }

    const translateY = scrollY + maxTranslate;

    // Calculate grayscale based on translateY's proportion to maxTranslate
    const grayscale = Math.round((translateY / startingHeight) * 100);

    // Calculate opacity based on translateY
    let opacity =
      (translateY - minOpacityTranslateY) /
      (maxOpacityTranslateY - minOpacityTranslateY);
    opacity = Math.min(Math.max(opacity, 0), 0.7); // Clamp the value between 0 and 0.7

    if (translateY >= maxTranslate) {
      setImageRevealed(true);
      return { translateY: maxTranslate, grayscale: 0, opacity: 0.7 };
    }

    return { translateY, grayscale, opacity };
  }

  const { translateY, grayscale, opacity } = calculateImageProperties();

  return (
    <Wrapper
      id="home"
      className="container flexSpaceCenter"
      style={{ backgroundColor: `rgba(166, 188, 255, ${opacity})` }}
    >
      <LeftSide className="flexCenter">
        <Heading1>The Age of Reinvention</Heading1>
        <Body1
          minFontSize="1.5rem"
          style={{ paddingTop: "20px", paddingBottom: "40px" }}
        >
          We help the over 45s shape their lives by providing personalised life
          plans for longevity and ageing well. From managing money, staying
          healthy and continuing to learn, our goal is to help improve work-life
          balance, whilst keeping connected and pursuing passions.
        </Body1>
        <Button
          to="/manifesto"
          text="Our Manifesto"
          color="white"
          arrow="none"
        />
        <ArrowDown marginTop="50px" marginBottom="50px" />
      </LeftSide>
      <AnchoredImage
        src={HeaderImage}
        alt="Anchored Image"
        translateY={translateY}
        grayscale={grayscale}
      />
    </Wrapper>
  );
}

const Wrapper = styled.section`
  position: relative;
  padding-top: 80px;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;

  @media (max-width: 1080px) {
    flex-direction: column;
    min-height: 1400px;
  }
  @media (max-width: 960px) {
    min-height: 1200px;
  }
  @media (max-width: 760px) {
    min-height: 1080px;
  }

  // Background colour transition
  background-color: rgba(0, 0, 0, 0.2);
`;

const LeftSide = styled.div`
  width: 50%;
  height: 100%;

  @media (max-width: 1080px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: left;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
  }

  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;

const AnchoredImage = styled.img`
  position: absolute;
  transition: transform 0.3s ease-out;
  bottom: ${({ translateY }) => translateY}px;
  filter: grayscale(${({ grayscale }) => grayscale}%);

  right: -15%;
  transform: scale(0.65);
  transform-origin: bottom; /* Anchor the transform to the bottom */
  max-width: 90%;
  height: auto;
  z-index: 1;

  @media (max-width: 1080px) {
    right: auto;
    transform: scale(0.85);
  }
`;
