import React from "react";

// Sections
import TopNavbar from "components/nav/TopNavbar";
import JoinBeta from "components/common/RegisterNow";
import Footer from "components/common/Footer";

export default function Media() {

    return (
        <>
            <TopNavbar />

            <JoinBeta />
            <Footer />
        </>
    );
}
