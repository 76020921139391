import React from "react";
import styled from "styled-components";

// assets
import image1 from "assets/img/backgrounds/dandelionEarth.png";
import image2 from "assets/img/articleheaderimages/catherine-moran-image-mind-wandering.jpg";
import image3 from "assets/img/articleheaderimages/Guardian-article-image.jpg";
import image4 from "assets/img/articleheaderimages/honeygood.jpg";
import image5 from "assets/img/articleheaderimages/due-best-business.png";
import image6 from "assets/img/articleheaderimages/longevity-dividend.jpg";

// components
import { Grid } from "@mui/material";
import { Heading4 } from "components/TextStyles";
import {
  ArticlesContainer,
  NewsContainer,
} from "components/styledsections/ResourcesStyledSections";

const Articles = [
  {
    image: image2,
    isPopular: true,
    category: "Health",
    date: "February 10, 2021",
    title:
      "Young and Restless, Old and Focused: Age-Differences in Mind-Wandering",
    subtitle:
      "New research suggests that older adults can be more focused, less impeded by anxiety and less mentally restless than younger adults.",
    articleURL:
      "https://www.tcd.ie/news_events/articles/young-and-restless-old-and-focused-age-differences-in-mind-wandering/",
  },
  {
    image: image6,
    isPopular: true,
    category: "Longevity",
    date: "July 20, 2023",
    title: "Longevity Dividend Magazine",
    subtitle: "The economics of healthier, longer and more productive lives",
    articleURL: "https://profandrewjscott.com/elementor-4195/",
  },
  {
    image: image3,
    isPopular: false,
    category: "Health",
    date: "September 25, 2018",
    title: "Loneliness among over-50s 'is looming public health concern'",
    subtitle:
      "Greater numbers will feel impact of widowhood, poor finance and ill-health, says Age UK.",
    articleURL:
      "https://www.theguardian.com/society/2018/sep/25/loneliness-among-over-50s-is-looming-public-health-concern",
  },
  {
    image: image4,
    isPopular: false,
    category: "Social",
    date: "November 19, 2023",
    title: "Feeling lonely and invisible as a woman over 50?",
    subtitle:
      "Many women over 50 report feeling lonely and invisible. After a couple of years of pondering, I’ve found some common threads as to why.",
    articleURL: "https://www.honeygood.com/why-women-feel-invisible-after-50/",
  },
  {
    image: image5,
    isPopular: false,
    category: "Diversity",
    date: "May 10, 2023",
    title: "The 9 Best Businesses to Start Over 60",
    subtitle:
      "For those with the itch, starting a business over the age of 60 is entirely doable. Age can give many advantages, including experience and wisdom.",
    articleURL: "https://due.com/best-business-to-start-over-60/",
  },
];

const News = [
  {
    image: image1,
    isTrending: true,
    title: "The rise of organ-on-chip and genome editing",
  },
  {
    image: image1,
    isTrending: true,
    title: "Tokenisation plan to save costs in Australian financial markets",
  },
];

export default function Media() {
  return (
    <>
      <Wrapper className="container">
        <Grid container columnSpacing={4}>
          <Grid item xs={12} lg={8}>
            <VerticalSectionWrapper>
              <Heading4>Articles & Media</Heading4>
              <Grid
                container
                bgcolor="transparent"
                spacing={4}
                justifyContent="flex-start"
                width="auto"
              >
                {Articles.map((article, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <ArticlesContainer
                      image={article.image}
                      isPopular={article.isPopular}
                      category={article.category}
                      date={article.date}
                      title={article.title}
                      subtitle={article.subtitle}
                      articleURL={article.articleURL}
                    />
                  </Grid>
                ))}
              </Grid>
            </VerticalSectionWrapper>
          </Grid>
          <Grid item xs={12} lg={4}>
            <VerticalSectionWrapper>
              <Heading4>Latest News</Heading4>
              <Grid
                container
                bgcolor="transparent"
                spacing={4}
                justifyContent="flex-start"
                width="auto"
              >
                {News.map((article, index) => (
                  <Grid item xs={12} sm={6} lg={12} key={index}>
                    <NewsContainer
                      image={article.image}
                      isTrending={article.isTrending}
                      title={article.title}
                    />
                  </Grid>
                ))}
              </Grid>
            </VerticalSectionWrapper>
          </Grid>
        </Grid>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
  display: flex;
`;

const VerticalSectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding-top: 85px;
  padding-bottom: 65px;
`;
